/*
 * TODO add text blob after "the best out of it" about sustainable travel
 * TODO advertise Luxor
 *  https://www.revinate.com/blog/facebook-ad-targeting-hotels/
 *  https://www.travelboommarketing.com/blog/facebook-targeting-hotels/
 */
import m from 'mithril'
import { Button, Checkbox } from 'construct-ui'
import * as root from './view'
import * as $person from './view.person'
import GLightbox from 'glightbox'


function CarouselGallery(vnode) {
    let gallery = null
    let galleryid = null
    const randomid = 'gallery-' + Math.trunc(Math.random()*1000000)

    function creategallery() {
        if (!galleryid) {
            console.error('galleryid not set', galleryid)
            return // TODO try again
        }
        if (gallery) gallery.destroy()
        gallery = new GLightbox({selector: '.' + galleryid})
    }

    return {
        view: function(vnode) {
            galleryid = vnode.galleryid || randomid
            //console.debug('slidergallery', vnode)
            const imgs = vnode.attrs.images.map((img) => m('a', {href: img, class: galleryid, 'data-gallery': galleryid}, m('img', {src: img + '&thumbnail=1', alt: 'gallery image', style: 'height: 7rem; margin: 10px;'})))
            const style = 'margin: 1rem; overflow-y: hidden; overflow-x: auto; white-space: nowrap; text-align: center;'
            return m('div', {class: 'carousel-gallery ' + (vnode.attrs.class || ''), style: style}, imgs)
        },
//         onupdate: function(vnode) {
//             creategallery()
//             console.debug('onupdate: gallery created', gallery)
//         },
        oncreate: function(vnode) {
            creategallery()
            //console.debug('oncreate: gallery created', gallery)
        },
        onremove: function() {
            gallery && gallery.destroy()
        }
    }
}

function dateinput(vnode) {
    let _month, _day
    let START = vnode.attrs.from || (new Date().getTime())
    let DAYS = vnode.attrs.days || 60
    const updatehandler = vnode.attrs.onchange || ((date) => null)
    function getdate(mm, dd) {
        const d = new Date(START)
        const startyear = d.getFullYear()
        const startmonth = d.getMonth()
        d.setDate(dd + 1)
        d.setMonth(mm)
        d.setFullYear(startyear + (startmonth>mm? 1: 0))
        d.setHours(12, 0, 0, 0)
        return d
    }

    function currentdate() {
        if (_month === undefined || _day === undefined)
            return new Date(0)
        return getdate(_month, _day)
    }

    function resetdate() {
        _month = _month !== undefined? _month: (new Date(START)).getMonth()
        _day = _day !== undefined? _day: (new Date(START)).getDate() - 1
    }

    let laststart = undefined

    return {
        view: function(vnode) {
            START = vnode.attrs.from || (new Date().getTime())
            DAYS = vnode.attrs.days || 60
            //console.debug('dateinput from', new Date(START), ', current=', currentdate())
            if (laststart !== START) {
                _month = _day = undefined
                //return
            }
            laststart = START
            resetdate()
            const list = []
            const DAY = 3600 * 24 * 1000

            let months = []
            let lastmonth = -1
            for (let i=0; i<DAYS; i++) {
                const month = (new Date(START + DAY * i)).getMonth()
                if (month !== lastmonth) months.push(month)
                lastmonth = month
            }

            months = months.map(function(month) {
                const date = new Date()
                date.setMonth(month)
                const monthname = date.toLocaleString('default', {
                    month: 'long'
                })
                const attrs = {value: month}
                if (month === _month)
                    attrs.selected = 'selected'
                return m('option', attrs, monthname)
            })
            let select = m(
                'select',
                {
                    //value: _month,
                    onchange: function(e) {
                        _month = parseInt(e.currentTarget.value)
                        if (currentdate().getTime() < START) {
                            _day = undefined
                            resetdate()
                        }
                        updatehandler(currentdate())
                        m.redraw()
                    }
                },
                months
            )
            list.push(m('div', {class: 'select'}, select))

            const MONTHDAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            let ndays = MONTHDAYS[_month]
            if (_month === 1) {
               let year = (new Date(START)).getFullYear()
               if ((new Date(START)).getMonth() > 1)
                   year++
               //console.debug('february: ', year)
               if (year % 4 === 0)
                  ndays = 29
            }
            let days = []
            for (let i=0; i<ndays; i++) {
                const d = getdate(_month, i)
                //console.debug(d, d.getTime(), ' <? ', START)
                //console.debug(`${d.getTime()} < ${START}?`, d.getTime() < START)
                if (d.getTime() < START) continue
                const attrs = {value: i}
                if (i === _day) attrs.selected = 'selected'
                days.push(m('option', attrs, i + 1))
            }
            select = m(
                'select',
                {
                    //value: _day,
                    onchange: function(e) {
                        _day = parseInt(e.currentTarget.value)
                        updatehandler(currentdate())
                        m.redraw()
                    }
                },
                days
            )
            list.push(m('div', {class: 'select'}, [
                select,
            ]))
            list.push(
                m('span', {
                    style: 'line-height: 3em; margin-left: .5em; font-size: .85em; color: gray;'
                }, currentdate().toDateString())
            )
            updatehandler(currentdate())
            //console.debug('current date=', currentdate())
            return list
        }
    }
}


const NOW = new Date()
NOW.setHours(0,0,0,0);
const MINDATEFROM = Math.max(1580515200000, NOW.getTime() + 1000 * 3600 * 24 * 3)

export const component = {
    preferences: {'date-from': MINDATEFROM, 'accommodation': true, 'food': true, 'entertainment': true},
    oninit: async function() {
        this.cities = m.route.param('tourcities').split('--').map(function (s) { return {
            country: s.split(':')[0],
            city: s.split(':')[1]
        }})
        console.log('this.cities', this.cities)

        // TODO grab info for all cities
        //this.citypromos = {}

        this.citypromos = {images: []}

        if (this.cities.length === 1) {
            try {
                this.citypromos = null
                const res = await $app.api.loadpromocity(this.cities[0].city)
                this.citypromos = res
                window.citypromos = res
                m.redraw()
            } catch (error) {
                this.citypromos = {images: []}
                console.log('city has no info')
                m.redraw()
            }
        }
    },
    view: function() {
        if (this.citypromos === null) {
            return m('div', {class: 'columns is-vcentered'}, [
                m('div', {class: 'column'}, 'Loading ...'),
                m('img', {class: 'column illustration is-hidden-mobile', src: '/img/illustrations/searching.svg'}),
            ])
        }
        const $state = root.$state
        const $app = root.$app
        const list = []
        const self = this
        const tour = this.cities
        const person = $state.person? $person.decodeid($state.person.id): {}
        if (!tour) {
            document.title = 'My Tour Plan at BackpackGo'
            document.querySelector('#meta-description').content = document.title
            return list
        }

        document.title = `My Tour Plan (${tour.map((c, _) => c.city).join(', ')}) at BackpackGo`
        document.querySelector('#meta-description').content = document.title

        list.push(m('h2', {class: 'title-2'}, [
            'Tour: ',
            tour.map((c, i) => [
                m('span', {class: 'city'}, c.city),
                (i < tour.length - 1 ? m('span', {class: 'larrow'}) : '')
            ])
        ]))


        const t = person.Name === undefined? '': ` ${person.Name}`
        list.push(m('p', {class: 'tip'}, m.trust(`
            Nice pick<span class="person-name">${t}</span>, you found a great tour!
            let us help you make the best out of it.
            <br>
            <!--
            <span style="font-style: italic;">
            Got questions? Check out our <a
                href="https://www.youtube.com/watch?v=tWfYy8JPYyg"
                target="_blank" rel="noopener"> video guide</a>.
            </span>
            -->
            `)))

        if (!$state.person) {
            list.push(m('p', {class: 'tip emphasized'}, m.trust(
                `It seems you are not logged in yet, it is a good idea to
                <a href="javascript:document.getElementById('person-form').scrollIntoView({behavior: 'smooth'})">log in</a> first.`)))
        }

        let imgs

        imgs = this.citypromos.images
                        .filter((img) => img.category === 'general')
                        .map((img) => img.url)
        list.push(m(CarouselGallery, {images: imgs}))

        list.push(m('p', {class: 'tip'}, 'Tell us a bit about your preferences.'))

        const city0 = tour[0].city
        const city1 = tour[tour.length-1].city
        let suffix0 = ` (at ${city0})`
        let suffix1 = ` (at ${city1})`
        if (city0 === city1) {
            suffix0 = suffix1 = ''
        }
        list.push(m('div', {class: 'columns'}, [
            m('label', {class: 'label column'},`Date of arrival${suffix0}`),
            m('div', {class: 'column'}, m(dateinput, {
                from: MINDATEFROM,
                onchange: function(date) {
                    self.preferences['date-from'] = date.getTime()
                    self.preferences['date-to'] = null
                    //m.redraw()
                }
            }))
        ]))

        if (self.preferences['date-from']) {
            list.push(m('div', {class: 'columns'}, [
                m('label', {class: 'label column'}, `Date of departure${suffix1}`),
                m('div', {class: 'column'}, m(dateinput, {
                    from: self.preferences['date-from'] + 1000 * 3600 * 24,
                    onchange: function(date) {
                        self.preferences['date-to'] = date.getTime()
                    }
                }))
            ]))
        }

        window.prefs = this.preferences


        const PREF_BOOK = {
            accommodation: ['1 room', '2 rooms', '3 rooms', 'apartment (2 rooms)', 'apartment (3 rooms)'],
            food: ['vegetarian', 'local', 'breakfast', 'pastry'],
            entertainment: ['sport', 'clubs', 'guided tours', 'concerts/music'],
        }

        for (let cat in PREF_BOOK) {

            /*
            list.push(m(Checkbox, {
                class: 'preference-category accordion-header',
                label: cat,
                defaultChecked: self.preferences[cat],
                onchange: function(e) {
                    //console.debug(e.currentTarget.checked)
                    self.preferences[cat] = e.currentTarget.checked
                }
            }))
            */
            list.push(m('label',
                        {class: 'label preference-category accordion-headers'},
                        cat))
            list.push(m('div', {class: 'seprator-dotted-blue'}))

            if (!self.preferences[cat]) continue
            list.push(PREF_BOOK[cat].map((pref) => m(Checkbox, {
                class: 'preference-item',
                label: pref,
                size: 'xl',
                defaultChecked: self.preferences[`${cat}:${pref}`],
                onchange: function(e) {
                    self.preferences[`${cat}:${pref}`] = e.currentTarget.checked
                }
            })))

            imgs = this.citypromos.images
                            .filter((img) => img.category === cat)
                            .map((img) => img.url)
            if (imgs.length > 0) {
                list.push(m(CarouselGallery, {images: imgs}))
            }

        }
        // TODO  number of travellers


        list.push(m('div'))

        if (!$state.person) {
            list.push(m('p', {class: 'tip'}, 'One last step to have your tour planned.'))
            list.push(m('div', {id: 'person-form'}, [m($person.form)]))
        } else {
            const btn = m(Button, {
                label: 'Plan my tour!',
                class: 'cta',
                style: 'min-width: 300px !important; margin-top: 2em;',
                onclick: async function(e) {
                    const $toaster = root.$toaster
                    let preferences = Object.assign({}, self.preferences)
                    preferences['date-from'] = (new Date(preferences['date-from'])).toISOString()
                    preferences['date-to'] = (new Date(preferences['date-to'])).toISOString()
                    preferences['@interests'] = $state.interests
                    const res = await $app.api.plantour(
                        $state.person.id, self.cities, preferences
                    )
                    if (res.status !== 'OK') {
                        $toaster.show({
                            intent: 'negative',
                            message: 'Ops, something went wrong! Please try again later'
                        })
                        m.redraw()
                        return
                    }
                    await $person.login($state.person.id)
                    $toaster.show({
                        size: 'xl',
                        intent: 'primary',
                        timeout: 10000,
                        onDismiss: function() {
                            m.route.set('/dashboard')
                        },
                        message: `
                            All good, we received your request. Please check your
                            dashboard often to see our suggestions for the tour.
                            You will be directed to your dashboard shortly (or click
                            X if you don't want to wait).
                        `
                    })
                    m.redraw()
                }
            })
            list.push(m('div', {class: 'has-text-centered'}, btn))
        }
        //list.push(m('p', {class: 'tip'}, 'BackpackGo is a free service for travellers, it is primarily a work of love :)'))

        return m('div', {class: 'tour-request section has-text-left'}, list)
    }
}
