import m from 'mithril'
import { Button } from 'construct-ui'
import * as root from './view'

import '../node_modules/construct-ui/lib/index.css'

const cityhightlights = {
    oninit: async function(email, otp) {
        this.highlights = await root.$app.api.loadguidehighlights()
        console.debug(this.highlights)
        m.redraw()
    },
    view: function() {
        return [
            m('h3', 'City Highlights'),
            m('div', {class: 'columns'}, [
                (this.highlights || []).map(function(record) {
                    function gotourl() {
                        m.route.set('/' +  record.url)
                        return false
                    }
                    return m('div', {class: 'column', onclick: gotourl}, [
                        m('a', {href: '/' + record.url, onclick: gotourl}, [
                                m('img', {src: record.photo,
                                          alt: `photo of ${record.city}`}),
                            m('h4', record.city),
                        ])
                    ])
                })
            ]),
        ]
    }
}
export default cityhightlights;
