'use strict'
// TODO load on-demand on older browsers
require('babel-core/register')
require('babel-polyfill')

let DEBUG = false
//let app_mock = null

import * as app from './app.js'

if (process.env.NODE_ENV !== 'production') {
    setTimeout(async function() {
        app_mock = await import('./app-mock.js')
        DEBUG = true
        console.debug('running in DEBUG mode with mock app')
    }, 0)
}

import * as view from './view.js'

function startapp() {
    const root = document.querySelector('#main')
    if (!root) {
        setTimeout(startapp, 250)
        return
    }
    view.start(root, DEBUG? app_mock: app)
}

startapp()
