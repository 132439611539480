import m from 'mithril'
import { Button } from 'construct-ui'
import * as root from './view'
import swal from 'sweetalert'

import '../node_modules/construct-ui/lib/index.css'

export async function login(personid, token) {
    const $state = root.$state
    const [res, mode] = await root.$app.api.login(personid, token)
    if (mode == 'login') {
        res.success = false
        if (res.status === 'OK') {
            $state.person = {
                id: personid,
                token: token,
                info: res.info,
                requests: res.requests,
                aoffers: res['accepted-offers']
            }
            localStorage.setItem('person', JSON.stringify($state.person))
            res.success = true
            if (!$state._update_task) {
                $state._update_task = setInterval(() => login($state.person.id), 7000)
            }
        }
    }
    return [res, mode]
}

export function decodeid(personid) {
    let person = {Name: null, place: null}
    let words
    if (personid.includes('@')) {
        const uname = personid.split('@')[0]
        words = uname.split(/[^a-zA-Z0-9]+/).map((w) => w.replace(/\d+$/, ''))
        person.Name = words.join(' ')
    } else {
        words = personid.toLowerCase().split(/[\.]/)
        //console.log('words', words)
        person.Name = words[0]
        person.place = words[2]
    }
    person.Name = person.Name.split(/\s+/).map((val) =>
         val.charAt(0).toUpperCase() + val.substr(1).toLowerCase()).join(' ')
    return person
}

export const form = {
    mode: 'login',
    view: function() {
        const $toaster = root.$toaster
        const list = []
        const self = this

        window.login = async function(id) {
            self.mode = 'login'
            id = id || document.querySelector('#input-person-id').value
            self.inputid = id
            $toaster.clear()
            swal({
                text: 'verifying your login, please wait ...',
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
            })
            const [res, mode] = await login(self.inputid)
            swal.close()
            if (res.status !== 'OK') {
                $toaster.show({
                    intent: 'negative',
                    message: "We don't recognize your credentials, please make sure you use the correct spelling and case"
                })
            } else {
                localStorage.setItem('otp-returnto', window.location.pathname)
            }

            self.mode = mode

            m.redraw()
        }

        if (this.mode == 'otp') {

            return m('p', {class: 'notification is-success is-light'}, 'We emailed you your login link, check your inbox (or spam/junk folders if you don\'t find the email) and click the link to continue.')
        }

        //list.push(m('div', {style: 'font-size: 18px; font-weight:  bold;'}, 'Who is it?'))
        //list.push(m('p', {style: 'color: var(--dgray);'}, 'Please enter your e-mail or identifier'))
        list.push(m('p', {style: 'color: var(--dgray);'}, 'Please enter your e-mail'))
        list.push(m('p', {class: 'tip', style: 'font-size: 14px; text-align: right;'}, 'we never spam or share your data to any 3rd party.'))
        list.push(m('div', {class: ''}, [
            m('input', {
                value: this.inpuid || '',
                style: 'margin-bottom: 1rem; min-width: 12em; width: 80%; height: 59px;',
                class: 'input is-large',
                placeholder: 'name@mail.com',
                id: 'input-person-id',
                'aria-label': 'person identifier'
            }),
            m('button', {
                id: 'button-login',
                class: 'button is-large is-rounded is-light',
                style: 'margin-left: -1.5em;',
                size: 'xl',
                onclick: async function() {window.login()}
            }, 'Login'),
            m('p', {class: 'tip', style: 'display: none;'}, m.trust(
            `If you don't wish to share your e-mail, totally fine! We
            can instead create an identifier for you.`)),
            m('button', {
                class: 'button is-large cta is-light',
                style: 'display: none',  // NOTE disable generated identifiers
                id: 'button-signup',
                size: 'xl',
                onclick: async function() {
                    const res = await root.$app.api.signup(this.inputid)
                    if (res.status !== 'OK') {
                        $toaster.show({
                            intent: 'negative',
                            message: 'Something wrong happened'
                        })
                        m.redraw()
                        return
                    }
                    const person = decodeid(res.personid)
                    $toaster.clear()
                    $toaster.show({
                        intent: 'primary',
                        timeout: 0,
                        message: m.trust(`
                            <h2>New Person Identifier</h2>
                            <div class="new-personid">
                            <p>
                            We generated this unique identifier for you: <br>
                            <input readonly class="personid input is-medium" onclick="this.select()" value="${res.personid}">
                            <br>
                            </p>
                            <footnote>This is both your identifier and password. Keep the identifier private and store it somewhere, it is your key to track your travels with us.</footnote>
                            <button class="button is-success is-large is-light"
                                onclick="window.login('${res.personid}')">
                                <strong>${person.Name}</strong> &nbsp; is good!</button>
                            <button class="button is-danger is-small is-light" style="margin-top: 25px; margin-left: 10px;"
                                onclick="document.getElementById('button-signup').click()">
                                Nope, create a new one!</button>
                            </div>
                        `)
                    })
                    m.redraw()
                }
            }, 'Create Identifier'),
//             m('p', {class: 'tip'}, m.trust(`
//             * What is a person identifier, you ask?
//             A person identifier is a cool, unique name we generate for you to be able
//             to track your tour plans. We chose to do things this way rather than
//             asking you for email, phone, or other personal details like others do.
//             This way you can be sure your privacy is fully protected with us.
//             `)),
        ]))

        list.push(m('div', {class: 'cta-register'}, m.trust(`

         <h3 style="font-size: 28px; color: var(--orange); padding: 15px;">Why Join Us?</h3>
         <p>
         BackpackGo ❤︎loves❤︎ travelers, with us you become part of a
         <strong>supportive community</strong>
         which will help you live
         <strong>authentic experiences</strong>
         in your travels, at <strong>affordable prices</strong>.
         We are your community, planner, and representative at businesses, let's
         make travel fun and accessible to everyone!
         </p>

         <img src="/img/illustrations/others/explore.svg" alt="explore journey">

        `)))

        return m('div', {class: 'id-form'}, list)
    }
}

export const otpcomponent = {
    process: async function(email, otp) {
        const returnto = localStorage.getItem('otp-returnto') || '/dashboard'
        const res = await root.$app.api.otpvalidate(email, otp)

        if (!res.token) {
            $toaster.show({
                intent: 'negative',
                message: "We don't recognize your credentials."
            })
            setTimeout(function() {window.location.href = '/'}, 5000)
            return
        }

        const res2 = await login(email, res.token)

        localStorage.removeItem('otp-returnto')
        localStorage.setItem('person-token', res.token)
        //m.route.set(returnto)
        setTimeout(function() {m.route.set(returnto)}, 500)

    },
    view: function() {},
    oninit: function() {
        const $toaster = root.$toaster
        const email = m.route.param('email')
        const otp = m.route.param('otp')

        if (this.called)
            return []

        this.called = true

        setTimeout(function() {otpcomponent.process(email, otp)}, 500)

        return []
    }
}
