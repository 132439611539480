'use strict'
import m from 'mithril'
import {
    ListItem, SelectList, Button, Icons, Card, Collapse
} from 'construct-ui'

//import {$state, $app} from './view'
import * as root from './view'

import '../node_modules/tom-select/dist/css/tom-select.bootstrap5.css'
import TomSelect from 'tom-select'

import cityhighlights from './view.cityhighlights'
//console.debug('view: ', root)


var countrycities = null

function cityselect2(vnode) {
    let country = vnode.attrs.country
    let city = null
    const $app = root.$app
    if (!$app) return []
    return {
        oninit: async function() {
            const countrycities = await $app.state.countrycities()

            if (country && vnode.attrs.city) {
                const c = countrycities[country].filter((r) => r[0] === vnode.attrs.city)
                if (c.length) {
                    city = c[0][0]
                    vnode.attrs.updatecountry(country)
                    vnode.attrs.updatecity(c[0])
                }
            }
        },
        view: function() {
            return [
                m(SelectList, {
                    items: Object.keys(countrycities).sort(),
                    filterable: true,
                    itemRender: (i) => m(ListItem, {
                        label: i,
                        selected: country === i
                    }),
                    itemPredicate: (q, i) =>
                        i.toLowerCase().includes(q.toLowerCase()),
                    onSelect: (i) => {
                        if (country === i) {
                            country = city = null
                        } else {
                            country = i
                            city = null
                        }
                        vnode.attrs.updatecountry(i)
                    },
                    trigger: m(Button, {
                        align: 'left',
                        compact: true,
                        iconRight: Icons.CHEVRON_DOWN,
                        sublabel: 'Country:',
                        label: `${country || '<please select>'}`,
                        class: vnode.attrs.class || ''
                        // style: 'min-width: 50vw; max-width: 700px'
                    })
                }),
                m(SelectList, {
                    items: (trycities[country] || []).sort(),
                    filterable: true,
                    itemRender: (i) => m(ListItem, {
                        label: i[0],
                        selected: city === i[0]
                    }),
                    itemPredicate: (q, i) =>
                        i[0].toLowerCase().includes(q.toLowerCase()),
                    onSelect: (i) => {
                        if (city === i[0]) {
                            city = null
                        } else {
                            city = i[0]
                        }
                        vnode.attrs.updatecity(i)
                    },
                    trigger: m(Button, {
                        align: 'left',
                        compact: true,
                        iconRight: Icons.CHEVRON_DOWN,
                        sublabel: 'City:',
                        label: `${city || '<please select>'}`,
                        class: vnode.attrs.class || ''
                        // style: 'min-width: 50vw; max-width: 700px'
                    })
                })
            ]
        }
    }
}

export function cityselect(vnode) {
    const $state = root.$state
    const $app = root.$app
    let selected = vnode.attrs.getcity()
    if (!$state) return []
    let $tomselect = null
    const ID = 'city-select-' + Math.round(1000 * Math.random())
    //$state.interests = selected
    //let countrycities

    function initselect() {
        if (!countrycities)
            return

        if ($tomselect)
            return

        try {
            $tomselect = new TomSelect('#' + ID, {
                maxItems: 1,
                closeAfterSelect: true,
                openOnFocus: true,
                //maxOptions: 100,
                //maxItems: 1,
                //persist: true,
                items: [selected],
                //createOnBlur: true,
                dropdownParent: 'body',
                create: false,
                allowEmptyOption: true,
                searchFeild: ['text'],
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                onChange: (val) => {
                    vnode.attrs.setcity(val)
                },
            })
            if (vnode.attrs.focus) $tomselect.focus()
            //console.log('tomselect created', $tomselect)
        } catch(error) {
            //console.log('failed to create tomselect', error)
            $tomselect = null
            setTimeout(initselect, 750)
        }
    }

//     $app.state.countrycities().then((x) => {
//         countrycities = x
//         console.log('loaded countrycities', countrycities)
//         initselect()
//     })

    return {
//         oninit: async function() {
//             //if ($state && $state.interests) selected = $state.interests
//             countrycities = await $app.state.countrycities()
//             console.log('countrycities loaded')
//             initselect()
//         },
        //oncreate: function(vnode) { initselect()},
        onremove: function(vnode) {
            $tomselect && $tomselect.destroy()
        },
        view: function() {
            if (!countrycities) {
                return []
            }
            initselect()
            let cities = []
            for (const entry of Object.entries(countrycities)) {
                cities.push(...entry[1].map((c) => [entry[0], c]))
                //if (cities.length > 200) break
            }
            //console.debug('cities=', cities)
            return m('select', {id: ID},
                     cities.map((e) => m('option', {
                         value: `${e[0]}|${e[1][0]}`
                    }, `${e[1][0]} (${e[0]})`))
            )
        }
    }
}

function now() {
    return (new Date()).getTime() / 1000
}

const citystr = root.citystr

function interestselect2(vnode) {
    // old version of interest selector
    const $state = root.$state
    const $app = root.$app
    let selected = $state? $state.interests: [] // ['Arts']
    if (!$state) return []
    let selectlist = null
    //$state.interests = selected
    return {
        oninit: () => {
            if ($state && $state.interests) selected = $state.interests
        },
        view: () => { selectlist = m(SelectList, {
            items: $app.state.interests,
            fluid: true,
            closeOnSelect: false,
            popoverAttrs: {isOpen: true},
            class: 'interests-popover',
            footer: [
                m('span',
                    {style: 'font-style: italic; font-size: 12px; color: gray;'},
                    `${($state.interests.length||'None')} selected`
                ),
                m(Button, {intent: 'positive', style: 'float: right;', iconLeft: Icons.CHECK, onclick: function() {
                    selectlist.state.isOpen = false
                    m.redraw()
                }}, 'X')
            ],
            itemRender: (i) => m(ListItem, {
                label: i,
                selected: selected.includes(i)
            }),
            itemPredicate: (q, i) =>
                i.toLowerCase().includes(q.toLowerCase()) ||
                        q.toLowerCase().includes(i.toLowerCase()),
            onSelect: function(i, e) {
                const idx = selected.indexOf(i)
                if (idx >= 0) {
                    selected.splice(idx, 1)
                } else {
                    selected.push(i)
                    while (selected.length > 5) {
                        selected.shift()
                    }
                }
                $state.interests = selected
            },
            trigger: m(Button, {
                align: 'left',
                compact: true,
                iconRight: Icons.CHEVRON_DOWN,
                sublabel: 'Inspirations:',
                label: selected.join(' ‧ '), // `${$selected.size} selected`,
                fluid: true,
                class: 'interests'
            })
        }); return selectlist}
    }
}

function interestselect(vnode) {
    const $state = root.$state
    const $app = root.$app
    let selected = $state? $state.interests: [] // ['Arts']
    if (!$state) return []
    let selectlist = null
    let $tomselect = null
    const ID = 'new-interests-select'
    //$state.interests = selected
    return {
        oninit: () => {
            if ($state && $state.interests) selected = $state.interests
        },
        oncreate: function(vnode) {
            console.debug(vnode.dom)
            $tomselect = new TomSelect('#' + ID, {
                maxItems: 5,
                items: selected,
                plugins: {
                    remove_button:{
                        title:'Remove this item',
                    },
                },
                //createOnBlur: true,
                dropdownParent: 'body',
                create: false,
                allowEmptyOption: true,
                onChange: (vals) => {
                    $state.interests = vals
                    if ($state.searchmode != 'A to B' && !$state.city0) {
                        $state.searchmode = 'Radius'
                        $state.radius = 'worldwide'
                    }
                },

            })
            //window.$tomselect = $tomselect
        },
        onremove: function(vnode) {
            $tomselect && $tomselect.destroy()
        },
        view: () => m('select', {id: ID},
                      $app.state.interests.map((i) => m('option', {value: i}, i))
                      )
    }
}

const searchmode = {
    view: function() {
        const list = []
        if (!root.$state) return ''
        list.push(m('button', {
            title: 'Search cities within a fixed distance from the origin',
            class: `button ${root.$state.searchmode=='Radius'?'is-dark is-outlined is-focused':''}`,
            style: 'margin-right: 5px;',
            onclick: function(e) { root.$state.searchmode = 'Radius'}
            }, 'Radius'))
        list.push(m('button', {
            title: 'Search cities between origin and destination',
            class: `button ${root.$state&&root.$state.searchmode=='A to B'?'is-dark is-outlined is-focused':''}`,
            onclick: function(e) { root.$state.searchmode = 'A to B' }
            }, 'A to B'))
        return m('div', {class: 'searchmode'}, list)
    }
}


function radiusselect(vnode) {
    const $state = root.$state
    const $app = root.$app
    let selected = vnode.attrs.getvalue()
    if (!$state) return []
    let $tomselect = null
    const ID = 'radius-select-' + Math.round(1000 * Math.random())
    return {
        oncreate: function(vnode) {
            $tomselect = new TomSelect('#' + ID, {
                maxItems: 1,
                items: [selected],
                create: false,
                allowEmptyOption: true,
                searchFeild: ['text'],
//                 sortField: {
//                     field: "text",
//                     direction: "asc"
//                 },
                onChange: (val) => {vnode.attrs.setvalue(val)},
            })
        },
        onremove: function(vnode) {
            $tomselect && $tomselect.destroy()
        },
        view: () => {
            const options = ['50km', '100km', '250km', '500km',
                    '1000km', 'worldwide']
            return m('select', {id: ID},
                options.map((e) => m('option', {value: e}, e))
            )
        }
    }
}


const searchbutton = m(Button, {
    iconLeft: Icons.Search,
    intent: 'primary',
    class: 'search cta',
    style: 'width: 33vw !important; min-width: 200px !important; max-width: 600px !important;',
    title: 'Find travel ideas for my preferences',
    label: 'Go',
    size: 'l',
    fluid: true,
    onclick: async function(e) {
        const $state = root.$state
        const $app = root.$app
        if (!$state) return []
        const $toaster = root.$toaster

        const range = ($state.radius || '').replace('km', '')

        // validate
        if (!$state.interests || !$state.interests.length) {
            $toaster.show({
                intent: 'negative',
                message: 'Please choose your inspirations'
            })
            return
        }
        if (range!=='worldwide' && (!$state.city0)) {
            $toaster.show({
                intent: 'negative',
                message: 'Please select the origin'
            })
            return
        }
        if ($state.searchmode === 'A to B' && (!$state.city1)) {
            $toaster.show({
                intent: 'negative',
                message: 'Please select the destination'
            })
            return
        }
        if ($state.searchmode === 'A to B' && $state.city0 === $state.city1) {
            $toaster.show({
                intent: 'negative',
                message: 'Origin and destination should be different'
            })
            return
        }


        $state.interests.sort()
        const [country0, city0] = ($state.city0 || '|').split('|')
        const [country1, city1] = ($state.city1 || '|').split('|')
        // console.debug($state.interests, city0, country0, range)
        let query
        if ($state.searchmode === 'A to B') {
            query = '/tour/' + $state.interests.join(',') +
                        '@' + citystr(city0, country0) + '--' + citystr(city1,  country1)
        } else {
            query = '/tour/' + $state.interests.join(',') +
                (range === 'worldwide' ? ''
                    : ('@' + citystr(city0, country0) + '~' + range + 'km'))
        }

        // persist query
        const params = {}
        for (let key of ['interests', 'city0', 'city1', 'searchmode', 'radius']) {
            params[key] = $state[key]
        }
        localStorage.setItem('search-params', JSON.stringify(params))

        $state.results = null
        m.route.set(query)
    }
})

export function component() {
    const $state = root.$state
    const $app = root.$app
    let focus = null
    if (!$state) return []
    //let countrycities

    function togglefocus(part) {
        focus = focus === part? null: part
        m.redraw()
    }

    return {
        oninit: async function() {
            countrycities = await $app.state.countrycities()
            let params = localStorage.getItem('search-params')
            try {params = JSON.parse(params)} catch {return}
            if (!params || typeof params != 'object') return
            for (let key of ['interests', 'city0', 'city1', 'searchmode', 'radius']) {
                if (! (key in params))
                    continue
                $state[key] = params[key]
            }
        },
        view: function() {
            const _presentcity = function(c) {
                const [country, city] = (c || '|').split('|')
                const ccode = $app.state.countrycode[country]
                //console.debug(country, ccode)
                if (!ccode) return null
                return `<li class="fi fi-${ccode.toLowerCase()}"></li>${city}`
            }

            let msg = {
                inspiration: ($state.interests && $state.interests.length)?
                    ($state.interests.join('</span><span>')):
                    'What inspires you to travel?',
                city0: _presentcity($state.city0) || 'Where do you want to start?',
                city1: _presentcity($state.city1) || 'Where would you like to go?',
                radius: $state.radius || 'How far do you want to go?',
            }
            for (let k in msg) msg[k] = `<span>${msg[k]}</span>`
            const part2class = {}
            for (let part of ['inspiration', 'city0', 'city1', 'radius']) {
                const exp = focus===part? 'expanded': 'collapsed'
                let set = !!$state[part]? 'is-set': ''
                if (part === 'inspiration') {
                    set = ($state.interests && ($state.interests.length > 0))? 'is-set': ''
                }
                part2class[part] = `${exp} ${set}`
            }

            let summary = ''
            if (($state.interests && $state.interests.length>0) &&
                ($state.city0 || $state.radius === 'worldwide')
            ) {
                summary = `You are looking for
                    <span>${$state.interests.join(' & ')}</span>
                `
                if ($state.city1) {
                    summary += `while travelling from <span>${_presentcity($state.city0)}</span> to <span>${_presentcity($state.city1)}</span>`
                } else if ($state.radius) {
                    if ($state.radius === 'worldwide') {
                        summary += 'all over the world'
                    } else {
                        summary += `in a circle of <span>${$state.radius}</span> radius around the city <span>${_presentcity($state.city0)}</span>`
                    }
                } else {
                    summary = ''
                }
            }

            return m('div', {class: 'searchbox'}, [
                m('h2', 'Your Journey Starts Here'),
                m('div', {style: 'margin-bottom: 2em;', class: 'seprator-dotted'}),
                m('div', {class: `part inspiration ${part2class.inspiration}`, onclick: () => {togglefocus('inspiration')}}, [
                    m('div', {class: `header ${part2class.inspiration}`}, 'Inspiration'),
                    m('p', {class: 'tip ' + (focus==='inspiration'? 'hidden': '')},
                            m.trust(msg.inspiration))
                ]),
                m(Collapse, {duration: 500, isOpen: focus==='inspiration'}, [
                    m(interestselect),
                    m('hr'),
                ]),
                //m('div'),

                m('div', {class: `part origin ${part2class.city0} ` + (focus==='city1'||$state.radius!=='worldwide'? '': 'disabled'), onclick: () => {togglefocus('city0')}}, [
                    m('div', {class: `header ${part2class.city0}`}, 'Origin'),
                    m('p', {class: 'tip ' + (focus==='city0'? 'hidden': '')},
                        m.trust(msg.city0)),
                    m('div', {class: (focus==='city0'? '': 'hidden')}, [
                        m(cityselect, {
                            //country: 'Germany',
                            //city: 'Berlin',
                            focus: true, //focus==='city0',
                            getcity: () => $state.city0,
                            setcity: (c) => {
                                $state.city0 = c
                                if (!c) {
                                    // selection cleared, don't switch focus
                                    return
                                }
                                focus = null
                                m.redraw()
                            },
                        }),
                        m('div', {class: 'tip'}),

                    ]),
                ]),

                m('div', {class: `part destination ${part2class.city1} ` + (focus==='city1'||$state.searchmode==='A to B'? '': 'disabled'), onclick: () => {togglefocus('city1')}}, [
                    m('div', {class: 'header ' + (focus==='city1'? 'expanded': 'collapsed')}, 'Destination'),
                    m('p', {class: 'tip ' + (focus==='city1'? 'hidden': '')}, m.trust(msg.city1)),
                    m('div', {class: (focus==='city1'? '': 'hidden')}, [
                        m(cityselect, {
                            //country: 'Germany',
                            //city: 'Berlin',
                            getcity: () => $state.city1,
                            setcity: (c) => {
                                $state.city1 = c
                                if (!c) {
                                    // selection cleared, don't switch focus
                                    return
                                }
                                $state.searchmode = 'A to B'
                                $state.radius = null
                                focus = null
                                m.redraw()
                            },
                        }),
                        m('div', {class: 'tip'}),
                    ]),
                ]),

                m('div', {class: `part radius ${part2class.radius} ` + (focus==='radius'||$state.searchmode==='Radius'? '': 'disabled'), onclick: () => {togglefocus('radius')}}, [
                    m('div', {class: 'header ' + (focus==='radius'? 'expanded': 'collapsed')}, 'Distance'),
                    m('p', {class: 'tip ' + (focus==='radius'? 'hidden': '')}, m.trust(msg.radius)),
                    m('div', {class: (focus==='radius'? '': 'hidden')}, [
                        m(radiusselect, {
                            getvalue: () => $state.radius,
                            setvalue: (r) => {
                                if (!r) {
                                    // selection cleared, don't switch focus
                                    return
                                }
                                $state.radius = r
                                $state.searchmode = 'Radius'
                                $state.city1 = null
                                focus = null
                                m.redraw()
                            },
                        }),
                        m('div', {class: 'tip'}),
                    ]),
                ]),

                m('p', {class: 'summary', style: 'margin-bottom: 1.5em;'}, m.trust(summary)),
                m('p', {class: 'tip', style: 'margin-bottom: 1.5em;'}, 'click to explore personalized travel ideas'),
                searchbutton,
                //m('div', {class: 'city-highlights'}, m(cityhighlights))
            ])
        }
    }
}

//export {component as searchbox} // TODO parcel exports that as undefinded

//module.exports = {component: searchbox}
