'use strict'

import m from 'mithril'
import '../node_modules/leaflet/dist/leaflet.css'
import * as root from './view'

let L = null    // to import leaflet asynchronously
const citystr = root.citystr


export const component = {
    oninit: async function() {
        const $state = root.$state
        const $app = root.$app
        if (!$state) return false
        let query = m.route.param('query')
        const queryisold = query.includes('@')
            && !query.split('@')[1].includes('~')
            && !query.split('@')[1].includes('--')
        if (query.includes('@worldwide') || queryisold) {
            query = query.split('@')[0]
            const tour = m.route.param('tour')
            window.location.href = `/tour/${query}/${tour}`
            return
        }

        let leaflet_import = null
        if (!L) {
            leaflet_import = import('leaflet')
        }

        ;[this.interests, this.mode, this.origin, this.dest,
            this.range, $state.results] = await root.searchtours(query)

        let idx = m.route.param('tour')
        if (!/^\+?(0|[1-9]\d*)$/.test(idx)) {
            window.location.href = `/tour/${query}`
            return
        }
        idx = parseInt(idx) - 1
        const tour = $state.results[idx]

        if (!tour) {
            window.location.href = `/tour/${query}`
            return
        }

        // find best cities for each interest
        const bestcity = {}
        for (let i=0; i<tour[0].sscore.length; i++) {
            const scores = tour.map((c) => c.sscore[i])
            const cityidx = scores.indexOf(Math.max(...scores))
            bestcity[tour[cityidx].name] = (bestcity[tour[cityidx].name] || []).concat([i])
        }
        //console.debug('bestcity', bestcity)

        this.cityinfo = this.cityinfo || {}
        for (const city of tour) {
            if (this.cityinfo[city.name]) continue
            let interestindexes = bestcity[city.name] || []
            const i = city.sscore.indexOf(Math.max(...city.sscore))
            if (!interestindexes.includes(i)) interestindexes.push(i)
            const listings = await $app.api.cityinfo(
                city.name,
                this.interests[interestindexes[0]]
            )
            this.cityinfo[city.name] = {
                interestindexes: interestindexes,
                listings: listings.slice(1),
                photos: listings[0]
            }
            //console.debug('city info: ', city.name, this.cityinfo)
        }
        //this.cityinfo[tour[0].name].open = true
        if (leaflet_import)
            L = await leaflet_import
        window.scrollTo(0, 0)
        m.redraw()
    },
    onremove: function() {
        if (this.map) {
            this.map.remove()
            this.map = null
        }
    },
    onupdate: function() {
        const $state = root.$state
        if (!$state) return

        if (!$state.results) return

        const idx = parseInt(m.route.param('tour')) - 1
        const tour = $state.results[idx]
        if (!document.querySelector(`#map-${idx}`))
            return

        if (this.map) {
            try {this.map.remove()}
            catch (e) {
                console.error('failed to remove the map, aborting update')
                return
            }
        }


        const map = new L.Map(`map-${idx}`)

        const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        const osmAttrib = 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
        const osm = new L.TileLayer(osmUrl, {
            minZoom: 1, maxZoom: 12, attribution: osmAttrib
        })
        // map.setView(new L.LatLng(lat, lng), 6)
        map.fitBounds(tour.map((c) => c.coords))
        map.addLayer(osm)

        const markers = ['1', '2', '3', '4', '5', '6', '7']
        tour.forEach(function(c, i) {
            const icon = L.divIcon({
                html: `<span class="cityseq">${markers[i]}</span><span class="cityname">${c.name}</span>`
                // iconSize: [38, 95],
            })
            L.marker(c.coords, {
                icon: icon
            }).addTo(map)
        })

        this.map = map
        //this.inited = true
    },
    view: function() {
        const list = []
        const $state = root.$state
        if (!$state) return
        if (!$state.results || !this.interests) return list
        const idx = parseInt(m.route.param('tour')) - 1
        const tour = $state.results[idx]
        // TODO onupdate is called too often

        list.push(m('a', {
            style: 'display: block; text-align: right; margin-left: 1.5em; font-size: 16px; color: var(--orange) !important;',
            onclick: function() {
                m.route.set('/tour/' + m.route.param('query'))
            }
        }, '< back to results'))

        const header = m('h2', [
            'Tour for ',
            this.interests.map(
                (i, idx) => m('span', {
                    class: `interest nn${idx} ${idx === this.interests.length - 1 ? 'last' : ''}`
                }, i)
            ),
            ': ',
            tour.map((c, i) => [
                m('span', {class: 'city'}, c.name),
                (i < tour.length - 1 ? m('span', {class: 'larrow'}) : '')
            ])
        ])

        const tip = m('p', {class: 'tip'}, `
            This is your tour details. Click "Go" to start planning. You can
            also explore other ideas using the arrows left and right.
        `)

        list.push(m('div', {class: 'columns is-vcentered'}, [
            m('', {class: 'column'}, [header, tip]),
            m('img', {class: 'column illustration  is-hidden-mobile', src: '/img/illustrations/locate.svg'}),
        ]))


        document.title = `Tour for ${this.interests.join(' & ')}: ${tour.map((c)=>c.name).join(' > ')} by BackpackGo`


        const self = this
        if(tour.map((c) => self.cityinfo[c.name]).includes(undefined)) {
            self.oninit()
            return list
        }
        tour.forEach(function(city) {
            const cityinfo = self.cityinfo[city.name]
            if (!cityinfo) return
            //console.debug('cityinfo=', cityinfo)
            list.push(m('h3',
                {
                    class: 'accordion-header',
                    //title: 'click to see notable attractions in this city',
                    onclicks: (e) => {
                        //NOTE: for now the notable attractions are of very low quality
                        for (const c in self.cityinfo) {
                            if (c !== city.name) { self.cityinfo[c].open = false }
                        }
                        cityinfo.open = !cityinfo.open
                    }
                }, [
                    m('span', {class: `fi fi-${city.country}`}),
                    m('span', {class: 'city'}, city.name),
                    cityinfo.interestindexes.map( (i) =>
                        m('span', {class: `interest nn${i}`}, self.interests[i])
                    ),    // NOTE n${i} would color-code the interests
                ]
            ))
            cityinfo.photos = cityinfo.photos || {}
            list.push(m('div', {class: cityinfo.open ? 'city-info ' : 'city-info hidden'}, [
                false && cityinfo.photos.video?     // NOTE disable videos for now
                    m('iframe', {
                        class: 'video-guide',
                        src: `https://www.youtube-nocookie.com/embed/${cityinfo.photos.video}`
                    }):
                    '',
                cityinfo.listings.length > 0? m('h4', 'Notable Attractions'): '',
                cityinfo.listings.map((info) =>
                    m('h5', {class: 'wikivoyage', title: info[3]},
                        info[2]))
            ]
            ))
        })


        list.push(m('div', {class: 'map', id: `map-${idx}`}))


        list.push(m('div', {class: 'fab'}, [
            m(
                'button',
                {
                    class: 'button prev',
                    title: 'previous idea',
                    onclick: function(e) {
                        const N = $state.results.length
                        const i = (idx - 1 + N) % N
                        e.preventDefault()
                        m.route.set(`/tour/${m.route.param('query')}/${i+1}`)
                        return false
                    }
                },
                'Previous <'
                //m('img', {src: '/img/md-skip-backward.svg'})
            ),
            m(
                'button',
                {
                    class: 'button next',
                    title: 'next idea',
                    onclick: function(e) {
                        const N = $state.results.length
                        const i = (idx + 1) % N
                        e.preventDefault()
                        m.route.set(`/tour/${m.route.param('query')}/${i+1}`)
                        return false
                    }
                },
                '> Next',
                //m('img', {src: '/img/md-skip-forward.svg'})
            ),
            m('br'),
            m(
                'button',
                {
                    class: 'button offers cta',
//                     style: 'height: 5em;width: 5em !important;min-width: max-content;border-radius: 5em;',
                    title: 'I love this one, let\'s plan the tour!',
                    onclick: function(e) {
                        const query = '/go/' +
                            tour.map((c) => citystr(
                                [c.name],
                                root.$app.state.countryname[c.country.toUpperCase()] || c.country.toUpperCase()
                            )).join('--')
                        e.preventDefault()
                        m.route.set(query)

                        return false
                    }
                },
                m.trust('Go')
            ),
        ]))


        return m('div', {class: 'tour-info container'}, list)
    }
}
