'use strict'

import INTERESTS from './data/interests.js'
//import COUNTRYCITIES from './data/countrycities.js'
import COUNTRYCODE from './data/countrycode.js'
const request = require('superagent')

const API = 'https://api.backpackgo.net'
//const API = 'http://test.backpackgo.net/api'

const countryname = {}
for (const name in COUNTRYCODE) countryname[COUNTRYCODE[name]] = name

// load large COUNTRYCITIES asynchronously
let COUNTRYCITIES = null
async function getcountrycities() {
    if (COUNTRYCITIES) {
        return COUNTRYCITIES
    }
    COUNTRYCITIES = await import('./data/countrycities.js')
    for (const country in COUNTRYCITIES) {
        if (COUNTRYCITIES[country].length == 0)
            delete COUNTRYCITIES[country]
    }
    return COUNTRYCITIES
}

export const state = {
    interests: INTERESTS,
    countrycities: getcountrycities,
    countrycode: COUNTRYCODE,
    countryname: countryname,
}

window.appstate = state

async function search(parameters) {
    const response = await request.post(`${API}/search.json`).retry(3).send(parameters)
    return response.body
}

async function cityinfo(city, interest) {
    const response = await request
        .get(`${API}/cityinfo.json`)
        .query({city: city, interests: interest})
    return response.body
}

async function login(personid, token) {
    //console.debug('loggin in with ', personid)
    if (!token)
        token = localStorage.getItem('person-token')

    if (!personid.includes('@') || token) {
        const response = await request
            .post(`${API}/person/info`)
            .send({personid: personid, token: token})
        return [response.body, 'login']
    }

    const response = await request
        .post(`${API}/person/otp/${personid}`)
        .send()
    return [response.body, 'otp']
}

async function otpvalidate(email, otp) {
    const response = await request
        .post(`${API}/person/otp/validate/${email}/${otp}`)
        .send()
    return response.body
}

async function signup(personid) {
    const response = await request
        .post(`${API}/person/id`)
        .send()
    return response.body
}

async function plantour(personid, tour, preferences) {
    const payload = {personid: personid, tour: tour, preferences: preferences}
    //console.debug(payload)
    const response = await request
        .post(`${API}/tourplanrequest`)
        .send(payload)
    return response.body
}

async function getrequests(personid) {
    const token = localStorage.getItem('person-token')
    const payload = {personid: personid, token: token}
    //console.debug(payload)
    const response = await request
        .post(`${API}/tourplanrequests`)
        .send(payload)
    return response.body
}

async function acceptoffer(personid, offerid) {
    const token = localStorage.getItem('person-token')
    const payload = {personid: personid, token: token, offerid: offerid}
    //console.debug(payload)
    const response = await request
        .post(`${API}/tourplanoffer/accept`)
        .send(payload)
    return response.body
}

async function loadguide(path) {
    const response = await request
        .get(`${API}/guide`)
        .query({id: path})
    return response.body
}

async function loadguidehighlights() {
    const response = await request
        .get(`${API}/guide/highlights`)
    return response.body
}

async function loadpage(path) {
    const response = await request.get(`${API}/page/${path}`)
    //console.debug('loadpage', path, response)
    return response.body || response.text
}

async function loadpromocity(city) {
    const response = await request.get(`${API}/promo-city?id=${city}`)
    const body = response.body
    body.images = body.images.map((img) => ({
        url: `${API}/promo-city?id=${city}&image=${img}`,
        category: img.startsWith('property')? 'accommodation': 'general',
    }))
    return body
}

export const api = {
    URL: API,
    search: search,
    cityinfo: cityinfo,
    login: login,
    plantour: plantour,
    getrequests: getrequests,
    acceptoffer: acceptoffer,
    loadguide: loadguide,
    loadguidehighlights: loadguidehighlights,
    loadpromocity: loadpromocity,
    loadpage: loadpage,
    signup: signup,
    otpvalidate: otpvalidate
}
