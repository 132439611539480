'use strict'
// view layer for web based on mithriljs
// to navigate somewhere: m.route.set

if (!Element.prototype.matches) {
    // polyfill for stupid browsers, i.e. IE
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector
}


import m from 'mithril'
import {
    ListItem, SelectList, Button, Icons, RadioGroup, Card, Input, Toaster,
    Checkbox, Icons, MenuItem, PopoverMenu, MenuHeading
} from 'construct-ui'
import * as searchbox from './view.searchbox'
import * as tourlist from './view.tourlist'
import * as tourinfo from './view.tourinfo'
import * as person from './view.person'
import * as dashboard from './view.dashboard'
import * as tourrequest from './view.tourrequest'
import '../node_modules/construct-ui/lib/index.css'
import '../node_modules/bulma/css/bulma.css'
import '../css/style.css'
import '../node_modules/glightbox/dist/css/glightbox.css'
import GLightbox from 'glightbox'
import posthog from 'posthog-js'

export let $app = null
export const $state = {
    interests: [],
    searchmode: null, //'Radius',
    radius: null, //'100km'
}
window.viewstate = $state
window.m = m

export const $toaster = new Toaster()
window.$toaster = $toaster

const PRIVACY_POLICY_VERSION = 'DEC23'

window.acceptCookies = function(consent) {
    if (consent === 'none') {
        localStorage.setItem = function() {console.debug('I will not store any thing')}
    } else if (consent === 'functional') {
        localStorage.setItem('privacy-accepted', PRIVACY_POLICY_VERSION)
    } else if (consent === 'all') {
        localStorage.setItem('privacy-accepted', PRIVACY_POLICY_VERSION)
        // TODO posthog
        posthog.init('phc_l8tjfZ7GapUi4cQ29APzbZNErJ53uj4okt9Rvxy6NCr', { api_host: 'https://app.posthog.com' })

    } else {
        console.error('unknown consent', consent)
    }
    $toaster.getToasts().map((t) => $toaster.dismiss(t.key))
}

function loadjscssfile(filename, filetype){
    let fileref
    // from http://www.javascriptkit.com/javatutors/loadjavascriptcss.shtml
    if (filetype==="js"){ //if filename is a external JavaScript file
        fileref=document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype==="css"){ //if filename is an external CSS file
        fileref=document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)

    }
    if (typeof fileref!="undefined") {
        document.getElementsByTagName("head")[0].appendChild(fileref)
        console.debug('added element to head', fileref)
    }
}

export function start(root, app) {
    // TODO doesn't work!!
    loadjscssfile('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap', 'css')
    loadjscssfile('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.2/css/flag-icons.min.css', 'css')

    //console.debug(app)
    $app = app
    //window.$app = $app
    for (const name in $app.state.countrycode) countrycode2name[$app.state.countrycode[name]] = name
    m.route.prefix = ''
    m.route(root, '/', {
        '/': routeresolver(searchbox.component),
        '/otp/:email/:otp': person.otpcomponent,
        '/tour/:query': routeresolver(tourlist.component),
        '/tour/:query/:tour': routeresolver(tourinfo.component),
        '/go/:tourcities': routeresolver(tourrequest.component),
        '/dashboard': routeresolver(dashboard.component),
        '/guide/:path...': routeresolver(guide),
        '/p/:path...': routeresolver(page),
        '/privacy': routeresolver(privacy)
    })

    document.querySelector('#logo').onclick = function(e) {
        e.preventDefault()
        m.route.set('/')
        return false
    }

    document.querySelectorAll('footer a.internal').forEach(function(a) {
        a.onclick = function(e) {
            //e.preventDefault()
            m.route.set(a.rel)
            return false
        }
    })

}

function routeresolver(comp) {
    return {render: () => m(layout, m(comp)), onmatch: () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}
}

const layout = {
    oncreate: async function() {
        if (window.location.pathname.length >= 4 && window.location.pathname[0] === '/') {
            m.route.set(window.location.pathname)
        }
        const p = JSON.parse(localStorage.getItem('person') || 'null')
        //console.debug('stored person', p)

        if (p) {
            const [res, _] = await person.login(p.id, localStorage.getItem('person-token'))
            if (res.success)
                m.redraw()
        }

        /*
        const loadpages = async function() {
            console.debug('about to load pages')
            let l = $app.api.loadpage('page-segments/footer-left.md')
            let c = $app.api.loadpage('page-segments/footer-center.md')
            let r = $app.api.loadpage('page-segments/footer-right.md')
            l = await l
            c = await c
            r = await r
            //console.debug('loaded pages', marked)

            //https://www.travicion.com/api/page/poi-explorer/
            document.getElementById('footer-left').innerHTML = marked(l)
            document.getElementById('footer-center').innerHTML = marked(c)
            document.getElementById('footer-right').innerHTML = marked(r)
        }

        // NOTE disable editing footer
        //setTimeout(loadpages, 100)
        */
    },
    view: function(vnode) {
        const list = [vnode.children, m($toaster, {inline: true})]

        if (!sessionStorage.getItem('consent-shown') && localStorage.getItem('privacy-accepted') !== PRIVACY_POLICY_VERSION) {
            $toaster.clear()
            $toaster.show({
                intent: 'primary',
                timeout: 0,
                message: m.trust(`
                <h2>Cookies &amp; Privacy</h2>
                <p>
                We store a minimal number of functional cookies to ensure a smooth
                experience (more details can be found in our
                <a href="/privacy" target="_blank" rel="noopener"
                    style="color: #000 !important;">
                    Privacy Policy
                </a>.
                </p>
                <p>
                Moreover, we collect usage information to understand
                better how you use the site and improve it. The information
                is completely anonymous. We'd really appreciate it if you
                enable it.
                </p>
                <p>
                You can always review &amp; also revoke your consent using
                the links in the footer.
                </p>
                <button class="cui-button cui-fluid cui-basic cui-positive cui-active cui-lg" onclick="window.acceptCookies('all')">Continue with optimal settings</button>
                <button class="cui-button cui-basic" onclick="window.acceptCookies('functional')">Continue with minimal settings</button>
                `)
            })
            sessionStorage.setItem('consent-shown', '1')
        }

        // menu
        let noffers = 0
        let dashboard_text = 'Log in'
        let greeting = null
        if ($state.person) {
            dashboard_text = 'Dashboard'
            const p = person.decodeid($state.person.id)
            const reqs = $state.person.requests
            greeting = m(MenuHeading, `Hi ${p.Name} 👋`)
            let noffers = 0
            for (const r of Object.values(reqs))
                noffers += Object.keys(r.offers).length
            if (noffers > 0)
                dashboard_text += ` (${noffers})`
        }

        list.push(m(PopoverMenu, {
            trigger: m(Button, { iconLeft: Icons.MENU, basic: true, size: 'xl',
                                 class: 'top-menu-button', title: 'menu button' }),
            closeOnContentClick: true,
            content: [
                greeting,
                m(MenuItem, {
                    iconLeft: Icons.HOME,
                    label: 'Home',
                    onclick: () => m.route.set('/'),
                }),
                m(MenuItem, {
                    iconLeft: Icons.USER,
                    label: dashboard_text,
                    onclick: () => m.route.set('/dashboard'),
                }, 'child'),
                // TODO can't do absolute link
//                 m('a', {
//                     href: '#about',
//                     class: 'cui-button cui-align-left cui-compact cui-menu-item cui-basic',
//                 }, 'About Us'),
//                 m(MenuItem, {
//                     iconLeft: Icons.GLOBE,
//                     label: 'Get social',
//                     href: '#social',
//                 }),
//                 m(MenuItem, {
//                     iconLeft: Icons.MAIL,
//                     label: 'Contact',
//                     href: '#contact',
//                 }),
            ]
        }))

        /*
        TODO show user greeting
        if ($state.person) {
            const p = person.decodeid($state.person.id)
            const reqs = $state.person.requests
            let noffers = 0
            for (const r of Object.values(reqs))
                noffers += Object.keys(r.offers).length
            list.push(m('div', {
                class: 'person-info',
                title: 'This is you! Click to browse your information.',
                onclick: function() {
                    $toaster.clear()
                    $toaster.show({
                        intent: 'primary',
                        timeout: 0,
                        key: 'person-info',
                        message: m.trust(`
                        <h2>Person Info</h2>
                        <p>
                        full identifier:
                        <input readonly class="personid" onclick="this.select()" value="${$state.person.id}">
                        </p>
                        <p>
                            <a href="/dashboard">Dashboard</a>
                        </p>
                        <p class="is-pulled-right">
                            <a onclick="localStorage.clear(); location.reload()">logout</a>
                        </p>
                        `)
                    })
                }
            }, [
                m('span', [
                    m('span', {class: 'filler'}, 'Hi'),
                    m('span', {class: 'name person-name'}, p.Name),
                    noffers? m('span', {class: 'notifications'}, `${noffers}`): ''
                ])
            ]))
        } else {
            // not logged in
            list.push(m('div', {
                class: 'person-info',
                title: 'This is you! Click to browse your information.',
                onclick: function() {
                    m.route.set('/dashboard')
                },
                },
                [
                m('span', [
                    m('span', {class: 'filler'}, 'Hi'),
                    m('span', {class: 'name person-name'}, 'stranger'),
                ])
            ]))
        }
        */

        list.push(m('div', {id: 'last-element'}, ''))
        return list
    },
}

export function citystr(city, country) {
    // city := [name, lat, long]
    // return (parseFloat(city[1]).toFixed(2) + ',' +parseFloat(city[2]).toFixed(2))
    if (country.includes(' ')) {
        country = $app.state.countrycode[country]
    }
    return `${country}:${city}`
}

let countrycode2name = {}

export async function citylookup(cstr) {
    // console.debug('citylookup', cstr)
    const countrycities = await $app.state.countrycities()
    if (cstr === 'worldwide') { return [null, null, null] }
    let [country, city] = cstr.split(':')
    if (country.length === 2) {
        country = countrycode2name[country]
    }
    if (!countrycities[country]) {
        return [null, null, null]
    }
    const c = countrycities[country].filter((c) => c[0] === city)
    if (c.length !== 1) {
        console.error('citylookup failed', cstr, c)
        return [null, null, null]
    }
    return c[0]
}

export async function searchtours(query) {
    let [interests, location] = query.split('@')
    let origin, dest, range
    if (location === undefined) {
        origin = null
        range = -1
    } else if (query.includes('~')) {
        [origin, range] = location.split('~')
        origin = await citylookup(origin)
        range = range.replace('km', '')
    } else {
        [origin, dest] = location.split('--')
        origin = await citylookup(origin)
        dest = await citylookup(dest)
    }

    interests = interests.split(',')
    const mode = query.includes('--') ? 'a2b' : 'radius'
    let results = $state.results
    if (!results) {
        try {
            results = (await $app.api.search({
                interests: interests,
                mode: mode,
                sourcepoint: origin,
                destpoint: dest,
                sourcerange: range
            })).results
        } catch(err) {
            $toaster.show({
                intent: 'negative',
                message: `Sorry, something went wrong! Please refresh or go back
                    and change your search parameters.`,
                timeout: -1
            })
            //setTimeout(function() {window.location.href = '/'}, 5000)
        }
    }

    $state.interests = interests
    return [interests, mode, origin, dest, range, results]
}




const privacy = {
    view: function() {
        const list = [m('h2', 'Privacy Policy')]

        list.push(m('div', {class: 'privacy-text'}, m.trust(`
            We went out of our way to ensure your privacy is preserved with BackpackGo.
            This is a summary of the privacy features:
            <ul>
            <li>
                No personal information is stored, except the ones we receive automatically
                when you use the app.
            </li>
            <li>
                No sharing of your information to any third-party.
            </li>
            <li>
                Minimum number of cookies to ensure a smooth experience (around 3 cookies, your typical social network/email provider will use upwards of 40 cookies).
            </li>
            </ul>

            <div class="full-text">
            <h3>Full Text</h3>
            <iframe src="/data/privacy.txt"></iframe>
            </div>
        `)))

        return list
    }
}


const guide = {
    oninit: async function() {
        const path = m.route.param('path')
        try {
            this.content = await $app.api.loadguide(path)
        } catch(e) {
            console.log('Error loading guide', e)
            this.content = '404 not found'
        }

        try {
            this.promo_info = await $app.api.loadpromocity(this.content.info.city)
        } catch(e) {
            this.promo_info = null
        }

        m.redraw()
    },
    view: function() {
        if (!this.content)
            return
        const self = this
        self.gallerydivs = []
        let article = this.content['article.html']

        document.title = `${this.content.info.city} Travel Guide by BackpackGo`

        const path = m.route.param('path')

        // TODO use https://simplelightbox.com/ for image gallery
        // TODO sprinkle tour suggestions links

        function imagegallery(str) {
            const img = str.substring(1, str.length - 1)
            const nimg = self.content.images[img]
            const divid = 'gallery--' + img.replace(/[^a-zA-Z0-9_\-]/g, '-')
            const g = []
            for (let i=0; i<nimg; i++) {
                const src = $app.api.URL + `/guide?id=${path}&image=${img}&imageid=${i}`
                g.push(`
<a href="${src}" class="glightbox" data-glightbox="type: image;" data-gallery="${divid}">
<img class="collection" src="${src}" alt="photo gallery of ${img}">
</a>
                `)
            }

            self.gallerydivs.push(divid)
            return `<div class="gallery" id="${divid}">` + g.join('') + '</div>'
        }

        article = article.replace(/{[^}]+}/g, (str, _, __) => imagegallery(str))

        const city = this.content.info.city
        if (this.promo_info) {
            const promotion_link = 'https://t.me/+3X7hL76Eyw43NjM6'
            //console.debug('sprinkling promo link', promotion_link)

            const texts = [
                `plan your ${city} visit with us`,
                `explore ${city} like a local`,
                `unlock ${city}'s best with us`,
                `start your ${city} adventure`,
                `discover ${city}'s hidden gems`,
                `travel smart in ${city}`,
                `experience ${city} your way`,
                `create ${city} memories with us`,
                `let's plan ${city} together`,
                `get ready for ${city} fun`,
                `your ${city} journey begins`,
                `transform your visit to ${city}`,
                `explore ${city} effortlessly`,
                `immerse in ${city}'s culture`,
                `start your ${city} getaway now`,
                `enjoy ${city} like never before`,
                `discover the best of ${city}`,
            ];

            let i = 0
            article = article.replace(/(\<h3|$)/g, function() {
                const variation = Math.floor(Math.random() * texts.length)
                const text = texts[variation]
                const cls = i++%2 == 0? 'even': 'odd'
                return `
                    <a href="${promotion_link}"  target=”_blank”>
                    <div class="tour-promo ${cls} variation-${variation}"
                      style="background-image: url('/img/promo-icons/${i % 7}.jpg');">
                    We are your concierge: ${text}
                    </div></a><h3`
                })
        }
        //window.article = article
        const list = [m('article', {class: 'guide'}, m.trust(article))]

        //console.log('guide: ', list)

        return list
    },

   onupdate: function() {
       //console.debug('creating galleries', this.gallerydivs)
       window.gallery = new GLightbox({selector: '.glightbox'})
       //for (const divid of (this.gallerydivs || []) )
       //    new SimpleLightbox('#' + divid)

   }
}


const page = {
    loadarticel: async function(path) {
        let article = null
        try {
            article = await $app.api.loadpage(path)
        } catch(e) {
            console.log('Error loading page', e)
            article = '404 not found'
        }
        return article
    },
    oninit: async function() {
        //console.debug('initing page ...')
        const path = m.route.param('path')
        this.article = await page.loadarticel(path)
        this.path = path
        m.redraw()
    },
    onupdate: async function() {
        console.debug('onupdate page')
        const path = m.route.param('path')
        if (path === this.path)
            return
        this.article = await page.loadarticel(path)
        this.path = path
        m.redraw()
    },
    view: function() {
        if (!this.article)
            return
        console.debug('about to display article of length', this.article.length)
        const list = [m('article', {class: 'page'}, m.trust(this.article))]
        return list
    },
}
