'use strict'

import m from 'mithril'
import {
    ListItem, SelectList, Button, Icons, RadioGroup, Card
} from 'construct-ui'
import * as root from './view'


// tours list
//
function score2str(score) {
    let s = ''
    // flatten the distribution
    score = Math.ceil(Math.pow(score * 5 ** 5, 1 / 5))
    score = score * 15 + Math.round(Math.random() * 6 - 3)  // give illusion of multiple reviews
    return `${score}`
    for (let i = 0; i < score; i++) { s += '⋆' }
    return s
}

export const component = {
    oninit: async function() {
        const $state = root.$state
        if (!$state) return false
        let query = m.route.param('query')
        const queryisold = query.includes('@')
            && !query.split('@')[1].includes('~')
            && !query.split('@')[1].includes('--')
        if (query.includes('@worldwide') || queryisold) {
            query = query.split('@')[0]
            window.location.href = `/tour/${query}`
            return
        }
        console.log('searching query', query)
        ;[this.interests, this.mode, this.origin, this.dest,
            this.range, $state.results] = await root.searchtours(query)

        m.redraw()
    },
    view: function() {
        const $state = root.$state
        if (!$state) return
        //console.debug('showing results', $state.results)
        if (!$state.results || !this.interests) {
            const list = []
            list.push(m('div', {class: 'columns is-vcentered'}, [
                m('div', {class: 'column'}, 'Searching ...'),
                m('img', {class: 'column illustration is-hidden-mobile', src: '/img/illustrations/searching.svg'}),
            ]))

            return list
        }
        const list = []
        let mode = ''
        let title = 'Travel ideas for ' + this.interests.join(' & ')
        switch (this.mode) {
            case 'a2b': {
                mode = m('span', [
                    'between ',
                    m('span', {class: 'city'}, this.origin[0]),
                    ' & ',
                    m('span', {class: 'city'}, this.dest[0])
                ])
                title += ` between ${this.origin[0]} & ${this.dest[0]}`
                break
            }
            case 'radius': {
                mode = m('span', [
                    this.range < 0
                        ? ' all over the world'
                        : [` ${this.range}km around `, m('span', {class: 'city'}, this.origin[0])]
                ])
                title += ' ' + (this.range < 0? 'all over the world': `${this.range}km around ${this.origin[0]}`)
                break
            }
        }

        document.title = title + ' by BackpackGo'

        // in case no results were found
        if ($state.results.length == 0) {
            const header = m('h2', {class: 'column is-centered'},
                             `Sorry, we found no travel ideas for you! Please try another search.`)
            list.push(m('div', {class: 'columns is-vcentered'}, [
                header,
                m('img', {class: 'column illustration is-hidden-mobile', src: '/img/illustrations/searching.svg'}),
            ]))
            return m(
                'section',
                {class: 'search-results'},
                list
            )

        }

        const header = m('h2', {class: 'column is-centered', style: 'text-align: center;'}, [
            m('span', `We found ${$state.results.length} travel ideas for `),
            this.interests.map(
                (i, idx) => m('span', {
                    class: `interest n${idx} ${idx === this.interests.length - 1 ? 'last' : ''}`
                },
                i
                )
            ),
            mode,
            m('p', 'Click the ones you like for more details.'),
        ])

        list.push(m('div', {class: 'columns is-vcentered'}, [
            header,
            m('img', {class: 'column illustration is-hidden-mobile', src: '/img/illustrations/travel-ideas.svg'}),
        ]))

        //list.push(m('p', {class: 'tip'}, `
        //    We found ${$state.results.length} travel ideas for you. Click the
        //    ones you like for more details.
        //`))

        const results = $state.results
        const resultsdiv = []
        results.map((t, tindex) => resultsdiv.push(m(Card,
            {
                interactive: true,
                onclick: function(e) {
                    const query =
                        '/tour/' + m.route.param('query') + '/' + (tindex + 1)
                    //console.debug('going to ', query, tindex)
                    m.route.set(query)
                },
                elevation: 1,
                class: 'tiles '
                    + (Math.random() > -0.35 ? '' : 'horizontal') // TODO variety, mason-like layout
            },
            [
                t.map((c) => m('div', {class: 'city-header'}, [
                    m('span', {class: `fi fi-${c.country}`}),
                    m('span', {class: 'cityname'}, ' ' + c.name)
                ]))
            ],
            m('hr'),
            [
                m('div', {class: 'tour-info'}, [
                    m('span', {class: `distance d${Math.round(1.0 / t[0].score[1])}`}, `${Math.round(1.0 / t[0].score[1])}km`),
                    m('span', {class: 'score'}, score2str(t[0].normscore))
                ])
            ],
        )))
        list.push(m('div', {class: 'results-container'}, resultsdiv))
        return m(
            'section',
            {class: 'search-results'},
            list
        )
    }
}
