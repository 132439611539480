import m from 'mithril'
import { Button } from 'construct-ui'
import * as searchbox from './view.searchbox'
import * as root from './view'
import * as person from './view.person'
import '../node_modules/construct-ui/lib/index.css'


function _offer_view(offer) {
    const list = []
    list.push(m('div', {class: 'offer'}, [
        m('ul', offer.offers.map((o) => {
            const parts = o.text.split('-')
            const url = parts.splice(2).join('-')
            //console.debug('_offer_view', o.text, url, parts)
            return m('li', m.trust(`
                <h6>${parts[0]}</h6>
                <span class="location">${o.category} at ${o.city}</span>
                <span class="info">${parts[1]}</span>
                <a href="${url}" target="_blank">View Offer</a>
            `))
        })),
    ]))

    return list
}

function newrequest() {
    const $state = root.$state
    return {
        view: function() {
            return [
                $state.request_active === true? m(searchbox.cityselect, {getcity: () => null, setcity: (c) => $state.city0=c}): null,
                m(Button, {label: 'New tour', onclick: () => {$state.request_active = true}})
            ]
        }
    }
}

export const component = {
    requests: null,
    view: function() {
        const $state = root.$state
        const $app = root.$app
        const list = []
        document.title = `My BackpackGo`
        list.push(m('h2', 'My BackpackGo'))
        if (!$state.person) {
            document.title = `Login/Signup to see your content in BackpackGo`
            //list.push(m('p', {class: 'tip'}, 'We need to know who you are first.'))
            list.push(m(person.form))
            return list
        }
        const self = this

        if (Object.keys($state.person.aoffers).length) {
            list.push(m('h3', 'My Offers'))
            const offers = $state.person.aoffers
            list.push(m('div', 'These are the places you chose to visit in your next travel, please mention our site & have a great trip!'))
            for (const offerid in offers) {
                list.push(m('', _offer_view(offers[offerid])))
            }
        }

        const requests = $state.person.requests
        list.push(m('h3', 'Your Tours'))
        if (!Object.keys(requests).length) {
            const cities = [
                ['pt', 'Braga', '/go/Portugal:Braga'],
                ['eg', 'Cairo', '/go/Egypt:Cairo'],
                ['at', 'Vienna', '/go/Austria:Vienna'],
                ['hu', 'Budapest', '/go/Hungary:Budapest'],
                ['es', 'Jerez de la Frontera', '/go/Spain:Jerez%20de%20la%20Frontera'],
            ]
            const citylink = cities[Math.floor(Math.random()*cities.length)]
            list.push(m('span', m.trust(`
            No tours yet, <a href="/">search for new tour</a>
            or try out our featured city
            <a href="${citylink[2]}">
            <span class="fi fi-${citylink[0]}" style="margin: 0;"></span> ${citylink[1]}
            </a>
           `)))
        }

        const reqstable = []
        const sortedkeys = Object.keys(requests)
        //sortedkeys.sort((a, b) => Object.keys(requests[b].offers).length - Object.keys(requests[a].offers).length)
        sortedkeys.sort((a, b) => (
            new Date(requests[b].preferences['date-from']) -
            new Date(requests[a].preferences['date-from'])
        ))

        /* TODO fix cities are not 1-to-1 to recorded cities
        list.push(m(newrequest))
        */

        for (const requestid of sortedkeys) {
            const request = requests[requestid]
            const d1 = new Date(request.preferences['date-from']).toDateString()
            const d2 = new Date(request.preferences['date-to']).toDateString()
            const prefs = Object.keys(request.preferences).filter(
                (t) => 'date-from date-to'.indexOf(t) < 0 && !t.includes(':') && t !== '@interests'
            )
            const offers = request.offers
            const offerslist = []
            if (Object.keys(offers).length) {
                offerslist.push(m('h5', 'Offers'))
            }
            for (const offerid in offers) {
                const labels = 'Accept;Looks good;Take the offer'.split(';')
                const label = labels[Math.floor(Math.random()*3)]
                offerslist.push(m('div', {class: 'offer'}, [
                    m('', _offer_view(offers[offerid])),
                    m(Button, {
                        label: label,
                        title: 'I will use this Offer.',
                        intent: 'positive',
                        class: 'accept-offer',
                        size: 's',
                        onclick: async function() {
                            const res = await $app.api.acceptoffer(
                                $state.person.id, offerid
                            )
                            console.debug(res)
                            await login($state.person.id)
                            m.redraw()
                        }
                    })
                ]))
            }

            reqstable.push(m('div', {class: 'request'}, [
                m('h4', request.tour.map((t) => t.city).join(' - ')),
                m('p', {class: 'range'}, `between ${d1} and ${d2}`),
                m('p', {class: 'prefs'}, prefs.join(' - ')),
                m('div', {class: 'offers-table'}, offerslist)
            ]))

        }
        list.push(m('div', {id: 'tour-requests'}, reqstable))

        list.push(m('img', {src: '/img/illustrations/others/explore.svg', alt: 'explore the world'}))

        return list
    }
}
